// images
import pashuAadhaar from "assets/images/techSolutions/pashuAadhar.png";
import milkTrak from "assets/images/techSolutions/milkTrak.png";
import gauMandi from "assets/images/techSolutions/gauMandi.png";
import somaScan from "assets/images/techSolutions/somaScan.png";

export const ecoSystem = [
  {
    id: 1,
    image: pashuAadhaar,
    name: "Pashu Aadhaar",
    description: [
      "A cost-effective animal identification & disease traceability platform that enables facial recognition for bovines to create an Aadhar-like database for dairy cattle.",
      "The technology has been validated with NDDB across 5 states and 12 districts covering 7 major breeds of cattle and buffaloes and won the National Award under Animal Husbandry Startup Grand Challenge 2.0",
      "The platform is currently used in Tamil Nadu for creation of disease-free zones to promote dairy exports"
    ]
  },
  {
    id: 2,
    image: milkTrak,
    name: "MilkTrak",
    description: [
      "MilkTrak is an advanced and user-friendly platform that traces milk to prioritize transparency and quality.",
      "It allows consumers to confidently discover the origin of the milk and gain insights into its production journey.",
      "It provides complete information on the milk's production journey, from its source to the consumer's glass.",
      "It ensures transparency by offering detailed information on the milk's origin and production process, ensuring high-quality dairy products."
    ]
  },
  {
    id: 3,
    image: gauMandi,
    name: "GauMandi",
    description: [
      "GauMandi combines online trading with transparency and animal welfare commitment.",
      "GauMandi provides transparency on animal records for informed decision-making.",
      "Bharat Mandi has  collaborated with leading financial institutions to offer cattle loans and working capital loans with competitive interest rates delivered through GauMandi platform directly to farmers and cattle buyers.",
      "The platform offers financial products to dairy farmers in quicker, simpler and paperless mode and provides cashless simple repayment options through FPCs."
    ]
  },
  {
    id: 4,
    image: somaScan,
    name: "SomaScan",
    description: [
      "SomaScan is a digital device for early diagnosis of subclinical mastitis in dairy cattle to enable proactive mastitis management and ensure judicious usage of antibiotics.",
      "SomaScan v1 is being scientifically validated by ICAR - Indian Veterinary Research Institute, Bareilly, UP that automatically counts somatic cells in raw milk and can be deployed at milk collection centres to incentivise farmers with low SCC in milk."
    ]
  }
];
